
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Logo } from '@/components';
import useTranslation from 'next-translate/useTranslation';
export default function Disclaimer() {
    const { t } = useTranslation();
    return (<>
      <main>
        <div className="flex items-center justify-center h-screen">
          <div className="text-center max-w-[483px]">
            <h1 className="flex items-center justify-center">
              <Logo width="350" height="40"/>
            </h1>
            <p className="mb-10 text-base">{t('common:virtual_assistant')}</p>
            <p className="mb-6 text-base">{t('common:disclaimer_1')}</p>
            <a href="https://www.bottegaveneta.com/" className="bg-black text-white py-2 px-4 inline-block text-base">
              {t('common:disclaimer_link')}
            </a>
          </div>
        </div>
      </main>
    </>);
}

    async function __Next_Translate__getStaticProps__18e65aa8dcf__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/disclaimer',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18e65aa8dcf__ as getStaticProps }
  